import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/bs/layout"
//import Image from "../components/image"
import SEO from "../../components/bs/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

import product1 from "./../../images/moonamar-hladno-cijedeno-ulje-corokota-crnog-kima.png"
import product2 from "./../../images/moonamar-hladno-cijedeno-bundevino-bucino-ulje.png"
import product3 from "./../../images/moonamar-konopljino-ulje-iz-organskog-bio-sjemena.png"
import product4 from "./../../images/moonamar-hladno-cijedeno-djevicansko-laneno-ulje.png"

const Produkte = () => (
  <Layout>
    <SEO
      title="MOONAMAR - PRIRODNI DODACI PREHRANI I KOZMETIKA"
      description="MOONAMAR preparate biramo sa ljubavlju i predanosti i u želji da blagodati prirode u našim proizvodima budu dostupni svima."
    />

    <section
      id="_heroPage"
      className="hero is-large _o-nama"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">PROIZVODI</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div id="produkte_block" className="columns is-hidden-touch">
              <div className="column is-2 is-narrow">
                <Link to="/bs/proizvod/corokotovo-ulje">
                  <figure className="image">
                    <img
                      src={product1}
                      alt="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                      title="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                    />
                  </figure>
                </Link>
              </div>
              <div className="column is-2 is-narrow">
                <Link to="/bs/proizvod/bundevino-ulje">
                  <figure className="image">
                    <img
                      src={product2}
                      alt="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                      title="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                    />
                  </figure>
                </Link>
              </div>
              <div className="column is-2 is-narrow">
                <Link to="/bs/proizvod/konopljino-ulje">
                  <figure className="image">
                    <img
                      src={product3}
                      alt="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                      title="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                    />
                  </figure>
                </Link>
              </div>
              <div className="column is-2 is-narrow">
                <Link to="/bs/proizvod/laneno-ulje">
                  <figure className="image">
                    <img
                      src={product4}
                      alt="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                      title="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                    />
                  </figure>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div id="produkte_list" className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns __prod">
              <div className="column is-3">
                <nav className="_sidebar">
                  <Link to="/bs/proizvodi/#1">Čorokotovo ulje</Link>
                  <Link to="/bs/proizvodi/#2">Bundevino (bučino) ulje</Link>
                  <Link to="/bs/proizvodi/#3">
                    Konopljino ulje iz BIO sjemena
                  </Link>
                  <Link to="/bs/proizvodi/#4">Laneno ulje</Link>
                </nav>
              </div>
              <div className="column is-9">
                <h3 className="title">EKSKLUZIVNI IZBOR MOONAMAR PROIZVODA</h3>
                <p className="content">
                  U cilju očuvanja mnogobrojnih blagotvornih supstanci i
                  specifične upotrebe kao dadataka ishrani, MOONAMAR ulja se
                  proizvode blagom metodom hladnog cijeđenja, i ne podvrgavaju
                  se nikakvim dodatnim preradama.
                </p>
                <div id="1" className="columns __prod">
                  <div className="column is-4 is-desktop">
                    <figure className="image">
                      <img
                        src={product1}
                        alt="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                        title="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                      />
                    </figure>
                  </div>
                  <div className="column">
                    <p className="title is-4">Čorokotovo ulje</p>
                    <figure className="image is-mobile">
                      <img
                        src={product1}
                        alt="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                        title="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                      />
                    </figure>
                    <p className="content">
                      Slab imunitet čini tijelo podložno infekcijama a
                      neuravnotežen imunitet dovodi do alergijskih oboljenja.
                      Čorokot djeluje blagotvorno pri balansiranju imuniteta kao
                      temelja održavanja zdravlja.
                    </p>
                    <Link
                      to="/bs/proizvod/corokotovo-ulje"
                      className="button is-text is-small btn2"
                    >
                      VIŠE O PROIZVODU
                    </Link>
                  </div>
                </div>
                <div id="2" className="columns __prod">
                  <div className="column">
                    <p className="title is-4">Bundevino (bučino) ulje</p>
                    <figure className="image is-mobile">
                      <img
                        src={product2}
                        alt="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                        title="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                      />
                    </figure>
                    <p className="content">
                      Mnogobrojna naučna istraživanja urađena na sredovječnoj i
                      starijoj muškoj populaciji ukazuju na njegovo blagotvorno
                      dejstvo na prostatu. Redovna konzumacija pomaže organizmu
                      kod problema povećane prostate.
                    </p>
                    <Link
                      to="/bs/proizvod/bundevino-ulje"
                      className="button is-text is-small btn2"
                    >
                      VIŠE O PROIZVODU
                    </Link>
                  </div>
                  <div className="column is-4">
                    <figure className="image is-desktop">
                      <img
                        src={product2}
                        alt="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                        title="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                      />
                    </figure>
                  </div>
                </div>
                <div id="3" className="columns __prod">
                  <div className="column is-4">
                    <figure className="image is-desktop">
                      <img
                        src={product3}
                        alt="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                        title="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                      />
                    </figure>
                  </div>
                  <div className="column">
                    <p className="title is-4">Konopljino ulje iz BIO sjemena</p>
                    <figure className="image is-mobile">
                      <img
                        src={product3}
                        alt="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                        title="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                      />
                    </figure>
                    <p className="content">
                      Konopljin fantastičan biohemijski sastav je potencijal
                      koji je izdiže iznad svih predrasuda i ponovo uvodi u
                      upotrebu kao izvanrednu potporu organizmu protiv
                      metaboličkih poremećaja.
                    </p>
                    <Link
                      to="/bs/proizvod/konopljino-ulje"
                      className="button is-text is-small btn2"
                    >
                      VIŠE O PROIZVODU
                    </Link>
                  </div>
                </div>
                <div id="4" className="columns __prod">
                  <div className="column">
                    <p className="title is-4">Laneno ulje</p>
                    <figure className="image is-mobile">
                      <img
                        src={product4}
                        alt="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                        title="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                      />
                    </figure>
                    <p className="content">
                      Iznimno visoka koncentracija OMEGA-3 čini ga veoma
                      poželjnim dodatkom ishrani u prevenciji oboljenja krvnih
                      sudova, te samim tim i srčanog i moždanog udara.
                    </p>
                    <Link
                      to="/bs/proizvod/laneno-ulje"
                      className="button is-text is-small btn2"
                    >
                      VIŠE O PROIZVODU
                    </Link>
                  </div>
                  <div className="column is-4">
                    <figure className="image is-desktop">
                      <img
                        src={product4}
                        alt="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                        title="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Produkte
